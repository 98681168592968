
:root {
    --color-background: #EBEBEB;
    --color-card: #FFFFFF;
    --color-primary: #454545;
    --color-secondary: #D7D7D7;
    --color-accent: #00C1EB;
    --color-grey-text: #5f5f5f;
}

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
}

::-webkit-scrollbar {
    display: none;
}

body {
    font-family: "Arial", "sans-serif";
    background-color: var(--color-background);
    font-size: 0.9rem;
    line-height: 1.6;
    overflow-x: hidden;
}

h1 {
    font-size: 4vh
}

h2 {
    font-size: 3.0vh;
}

h3 {
    font-size: 2vh;
}

h4 {
    font-size: 1.6vh;
}

a {
    text-decoration: none;
    color: inherit;
}


ul {
    list-style: none;
}

img {
    display: block;
    object-fit: cover;
    width: 100%;
}

button:active {
    background: transparent;
}

.container {
    width: 98%;
    margin-inline: auto;
}


/*========================= navbar ======================== */

nav {
    width: 100vw;
    height: 8rem;
    display: grid;
    place-items: center;
    /*backdrop-filter: blur(10px);*/
    /*border-bottom: solid white 0.3px;*/
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
}

nav a {
    color: white;
}

.navContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.navBackground {
    width: 100vw;
    height: 8rem;
}

.navLogo {
    width: 8rem;
    display: block;
}

.navMenu {
    display: flex;
    align-items: center;
    gap: 4rem;
    font-size: 1.3rem;
    margin-right: 1.5rem;
    transition: 1s;
}

.navMenu li {
    transition: 300ms;
}

.navMenu li:hover {
    transform: scale(1.08);
}

.navToggleBtn {
    display: none;
    position: absolute;
    top: 2rem;
    right: 1.5rem;
    color: white;
    background: transparent;
    font-size: 1.5rem;
    cursor: pointer;
}

@media (max-width: 1000px) {

    nav {
        height: 5rem;
    }

    .navLogo {
        width: 5rem;
        display: block;
    }

}


@media (max-width: 600px) {

    nav {
        height: 7rem;
    }

    .navLogo {
        width: 7rem;
        display: block;
    }


    .navMenu {
        margin-right: 0;
        position: absolute;
        top: 5rem;
        right: 0;
        flex-direction: column;
        gap: 0;
        box-shadow: 1px 5px 14px 0 rgba(0, 0, 0, 0.6);
        opacity: 1;
    }

    .navMenu li {
        width: 100%;
    }

    .navMenu li a {
        display: block;
        background-color: white;
        padding: 0.8rem 2rem;
        color: black;
    }

    .navToggleBtn {
        display: inline-block;
    }

}


/*=============== Footer =======================*/

footer {
    width: 100%;
    display: flex;
    /*align-items: center;*/
    justify-content: center;
    background-color: var(--color-primary);
    left: 0;
    top: 0;
    z-index: 1;
    color: white;
    margin-top: 2rem;
}

.footerContainer {
    margin: 3rem;
}

.footerContent {
    width: 80vw;
    padding-bottom: 1vw;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-bottom: solid white 0.1vw;
}

.footerLogoContainer {
    /*position: absolute;*/
    /*left: 50%;*/
    /*transform: translateX(-50%);*/
    display: flex;
    justify-content: center;
    align-items: center;
    /*width: 20rem;*/
    /*margin-right: 3rem;*/
}

.footerLogo {
    width: 8rem;
    display: block;
}

.footerLeft, .footerRight {
    width: 30vw;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

@media (max-width: 700px) {

    .footerLogo {
        width: 4rem;
    }

    .footerContent {
        font-size: 0.4rem;
    }

    .footerContainer {
        margin: 1rem;
    }

}

/*===========SlideShow=============*/

.each-slide-effect div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 80vh;
    background-position: center;
}

@media (max-width: 700px) {

    .each-slide-effect div {
        height: 55vh;
    }

}

.indicators {
    position: relative;
    top: -50px;
}

.indicator {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 0.4rem;
    height: 0.4rem;
    cursor: pointer;
    background-color: white;
    border-radius: 50px;
    color: black;
    margin: 0.1rem;
    opacity: 0.4;
}

.indicator.active {
    color: #ffffff;
    opacity: 0.7;
}

.chevron {
    color: white;
    transform: scale(2);
    transition: 300ms;
}

.chevron:hover {
    transform: scale(2.5);
}


/*================ offers ==================*/

.offerContainer {
    display: flex;
    /*justify-content: center;*/
    align-items: center;
    flex-direction: column;
}


.featuredOffers {
    /*background-color: #00C1EB;*/
    width: 60%;
    max-width: 1000px;
    /*padding: 0 1rem 2rem 1rem;*/
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.normalOffers {
    /*background-color: #00C1EB;*/
    width: 60%;
    /*padding: 0 1rem 2rem 1rem;*/
    max-width: 1000px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 4vh;
    row-gap: 4vh;
}

.offersTitle {
    width: 80%;
    margin-bottom: 3vw;
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    padding: 10px;
    color: var(--color-grey-text);
    border-bottom: solid var(--color-grey-text) 1px;
}

@media (max-width: 1100px) {

    .offersTitle {
        font-size: 1.4rem;
    }

}

@media (max-width: 1100px) {

    .offersTitle {
        font-size: 1.1rem;
    }

}

@media (max-width: 500px) {

    .offersTitle {
        font-size: 0.8rem;
    }

}

/*==============features cards==================*/


.featuredCard {
    background: #fff;
    box-shadow: 1px 5px 14px 0px rgba(0, 0, 0, 0.6);
    /*max-width: 1000px;*/
    /*min-width: 100%;*/
    width: 100%;
    display: flex;
    flex-direction: row;
    border-radius: 2vw;
    position: relative;
    margin-bottom: 4vh;
    transition: 250ms;
    cursor: pointer;
}

.featuredCard:hover {
    transform: scale(1.02);
}


.emptyOffers {
    width: 100%;
    height: 60vh;
    margin-top: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 5vw;
    text-align: center;
    color: var(--color-grey-text);
    font-weight: bold;
}

@media (max-width: 800px) {

    .featuredOffers {
        width: 85%;
    }

    .normalOffers {
        width: 85%;
    }

    .normalOffers {
        column-gap: 2vh;
        row-gap: 2vh;
    }

    .featuredCard {
        margin-bottom: 2vh;
    }

    .emptyOffers {
        font-size: 7vw;
    }

}

.featuredCardContainer {
    display: grid;
    grid-template-columns: 1fr 2fr;
    width: 100%;
}

.featuredCardInfo {
    padding: 2.5vw 2vw 1.2vw 2vw;
    min-width: 100%;
    width: 100%;
}

.featuredCardImage {
    width: 100%;
    height: 100%;
    border-top-left-radius: 2vw;
    border-bottom-left-radius: 2vw;
    background-position: center center;
    background-size: cover;
}

.featuredCardTitle {
    margin: 0;
    font-size: 2rem;
    line-height: 1.8rem;
    font-weight: bold;
    color: var(--color-grey-text);
    padding-top: 1.4rem;
}

.featuredCardPrice {
    font-weight: bold;
    font-size: 1.8rem;
}

.featuredCardPrice span {
    border-bottom: solid var(--color-accent) 2px;
}

.featuredCardLocation {
    font-size: 1rem;
    color: var(--color-grey-text);
    padding: 1rem 0 0.7rem 0;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.cardPropertiesLine {
    margin-top: 0.3rem;
    width: 100%;
    height: 1px;
    background-color: var(--color-grey-text);
    opacity: 0.8;
}

.cardProperties {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    /*display: grid;*/
    /*grid-template-columns: repeat(4, 4vw);*/
    padding-top: 0.7rem;
}

.cardProperty {
    color: var(--color-grey-text);
    font-size: 1.5rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardProperty svg {
    margin-bottom: 0.2rem;
}

.propertyIcon {
    width: 2rem;
    height: auto;
}

.locationIcon {
    color: var(--color-grey-text);
    width: 1.2rem;
    height: auto;
    margin-bottom: 0.2vw;
}

@media (max-width: 1300px) {

    .featuredCardTitle {
        font-size: 1.4rem;
        line-height: 1.5rem;
        padding-top: 0.8rem;
    }

    .featuredCardPrice {
        font-size: 1.2rem;
    }

    .featuredCardLocation {
        font-size: 0.8rem;
        padding: 0.5rem 0 0.9rem 0;
    }

    .cardProperty {
        font-size: 1.2rem;
    }

    .propertyIcon {
        width: 1.4rem;
        height: auto;
    }

    .cardProperties {
        padding-top: 0.6rem;
    }

    .locationIcon {
        width: 0.9rem;
    }

}

@media (max-width: 500px) {

    .featuredCardTitle {
        font-size: 1rem;
        line-height: 1.1rem;
        padding-top: 0.6rem;
    }

    .featuredCardPrice {
        font-size: 0.9rem;
    }

    .featuredCardLocation {
        font-size: 0.6rem;
        padding: 0.4rem 0 0.4rem 0;
    }

    .cardProperty {
        font-size: 0.8rem;
    }

    .propertyIcon {
        width: 1rem;
        height: auto;
    }

    .cardProperties {
        padding-top: 0.5rem;
    }

    .locationIcon {
        width: 0.8rem;
    }

}


/*================== cards ====================*/


.card {
    transition: 250ms;
    cursor: pointer;
}

.card:hover {
    transform: scale(1.02);
}

.cardContainer {
    width: 100%;
    /*height: 30vw;*/
    border-radius: 2vw;
    font-size: 1rem;
    background-color: white;
    box-shadow: 1px 5px 14px 0px rgba(0, 0, 0, 0.6);
    padding: 0.5rem;
}


.cardImage {
    width: 100%;
    height: min(500px, 25vw);
    border-radius: 1.5vw;
    background-position: center center;
    background-size: cover;
}

.cardImage img {
    border-radius: 1.5vw;
}

.cardInfo {
    width: 100%;
    /*height: 300px;*/
    margin-top: 0.5rem;
    padding: 0.6rem;
}

.cardPrice {
    font-size: 2rem;
    font-weight: bold;
}

.cardPrice span {
    border-bottom: solid var(--color-accent) 2px;
}

.cardLocation {
    font-size: 1rem;
    color: var(--color-grey-text);
    padding: 0.3rem 0;
    font-weight: bold;
    display: flex;
    align-items: center;
}

@media (max-width: 1300px) {

    .cardPrice {
        font-size: 1.2rem;
    }

    .cardLocation {
        font-size: 0.8rem;
    }

}

@media (max-width: 1050px) {


    .cardInfo .cardProperty {
        font-size: 0.9rem;
    }

    .cardInfo .cardProperty svg {
        margin-bottom: 0;
    }

    .cardInfo .propertyIcon {
        width: 1.1rem;
    }

}

@media (max-width: 800px) {

    .cardContainer {
        padding: 0.2rem;
    }

    .cardImage {
        height: 35vw;
    }

    .cardInfo .cardProperty {
        font-size: 1rem;
    }

    .cardInfo .propertyIcon {
        width: 1.2rem;
    }

    .cardInfo {
        margin-top: 0.3rem;
        padding: 0.2rem;
    }

}


@media (max-width: 650px) {


    .cardInfo .cardProperties {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
    }

    .cardInfo .cardProperty {
        justify-content: left;
    }
}

@media (max-width: 500px) {

    .cardPrice {
        font-size: 0.8rem;
    }

    .cardLocation {
        font-size: 0.6rem;
    }

    .cardInfo .cardProperty {
        font-size: 0.8rem;
    }

    .cardInfo .propertyIcon {
        width: 1rem;
    }

}


/*================== about section ====================*/


.aboutSection {
    display: block;
    padding: 2rem 20%;
    width: 100%;
    background-color: var(--color-secondary);
    color: var(--color-primary);
    margin: 7vh 0;
    font-size: 1.5vw;
    text-align: justify;
}

.aboutTitle {
    font-size: 2vw;
    padding-bottom: 3.5vw;
    margin-bottom: 3.5vw;
    text-align: center;
    border-bottom: solid var(--color-grey-text) 1px;
    font-weight: bold;
}

.aboutText {
    margin: 2.3vw 0;
}

.aboutLearnMoreBtn {
    display: flex;
    justify-content: right;
    transition: 300ms;
}

.aboutLearnMoreBtn:hover {
    transform: scale(1.01);
}

.aboutLearnMoreBtn a {
    display: flex;
    align-items: center;
    text-align: center;
    padding: 0.2rem;
}

@media (max-width: 800px) {

    .aboutSection {
        display: block;
        padding: 2rem 7.5%;
        font-size: 3vw;
    }

    .aboutTitle {
        font-size: 3.4vw;
    }

    .aboutText {
        margin: 1.2vw 0;
    }

}


/*================== Error page =====================*/

.errorContainer {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.errorTitle {
    font-size: 8vw;
    color: #787878;
    width: 100%;
    text-align: center;
}

.errorText {
    font-size: 2.5vw;
    color: #5f5f5f;
    width: 100%;
    text-align: center;
}

.errorHomeBtn {
    width: 4vw;
    height: 2vw;
    border: solid black 1px;
    background: #5f5f5f;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.3vw;
    font-weight: bold;
    font-size: 0.8vw;
}


/*============ Imprint =================*/

.imprintText {
    width: 100%;
    margin: 3rem 0;
    padding: 4vw 10vw;
}

.imprintText p {
    margin: 1vw 0;
}


/*===================== Status messages ========================*/


.loadingStatus {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30vw;
    color: #5f5f5f;
}


/*================ House details ==================*/


.houseDetails {
    display: flex;
    justify-content: center;
    align-items: center;
}

.detailContainer {
    width: 100%;
    max-width: 1500px;
    padding: 3vw;
    /*background-color: blue;*/
}


.detailHeader {
    margin-top: 1vw;
    margin-bottom: 2vw;
}

.detailTitle {
    text-align: center;
    width: 100%;
    font-size: 3rem;
    color: var(--color-grey-text);
    font-weight: bold;
    padding: 1vw;
}

.detailTextContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.detailText {
    text-align: left;
    width: 100%;
    font-size: 1.6rem;
    max-width: 1100px;
    text-align: justify;
}


.detailText ol {
    margin-left: 3%;
}

@media (max-width: 800px) {

    .detailTitle {
        font-size: 2rem;
    }

    .detailText {
        font-size: 1.1rem;
    }

}

@media (max-width: 500px) {

    .detailTitle {
        font-size: 1.6rem;
    }

    .detailText {
        font-size: 1rem;
    }

}


/*============= Contact =================*/


.contact {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-background);
    margin-top: 6rem;
}

.contactForm {
    width: 80%;
    max-width: 700px;
    min-width: 350px;
    background-color: #efefef;
    padding: 25px 25px 30px;
    border-radius: 0.5rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    color: var(--color-primary);
    margin: 25px;
}

.contactForm h2 {
    font-size: 30px;
    text-align: center;
}

.contactForm label {
    color: var(--color-grey-text);
    font-weight: bold;
}


.inputBox {
    margin-top: 20px;
}

.contactField {
    width: 100%;
    height: 50px;
    background: transparent;
    border: solid #ddd 2px;
    outline: none;
    border-radius: 0.3vw;
    padding: 10px;
    font-size: 16px;
    color: var(--color-grey-text);
    margin-top: 8px;
}

.messageField {
    height: 200px;
    resize: none;
}

.contactSubmitBtn {
    width: 80%;
    height: 55px;
    background: var(--color-accent);
    border: none;
    border-radius: 0.3vw;
    box-shadow: 1px 5px 14px 0px rgba(0, 0, 0, 0.6);
    cursor: pointer;
    font-size: 1.1rem;
    color: white;
    font-weight: 500;
    margin-top: 20px;
    transition: 200ms;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
}


.contactSubmitBtn:hover {
    transform: scale(1.01) translate(-50%, 0);
}


/*============== contact page ========================*/

.contactInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
    width: 100%;
    padding: 25px 25px 30px;
    color: var(--color-primary);
    font-size: 1.8rem;
}

.contactTitle {
    font-size: 3.5rem;
    padding-bottom: 1.5vw;
    margin-top: 3.5vw;
    text-align: center;
    border-bottom: solid var(--color-grey-text) 1px;
    font-weight: bold;
    width: 60%;
}

@media (max-width: 700px) {

    .contactInfo {
        font-size: 5vw;
    }

    .contactTitle {
        font-size: 6vw;
    }

}


/*================ privacy page ==================*/

.privacyText {
    padding: 4vw 10vw;
    white-space: pre-wrap;
}

.privacyText p {
    margin: 0.2vw 0;
}

.privacyText h2 {
    margin-top: 2vw
}

.privacyText h3 {
    margin-top: 1.6vw
}
